@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
* {
  font-family: "Rubik", sans-serif;
  user-select: none;
  cursor: url("assets/cursor.png"), auto;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  /*width: 120px;*/
  background-color: rgba(255, 255, 255, 0.7);
  color: #000000;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
  position: absolute;
  z-index: 1;
  min-width: 70px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  margin-left: -50%; /* Use half of the width (120/2 = 60), to center the tooltip */
  margin-bottom: 10px;
}

.tooltip-bottom {
  /*width: 120px;*/
  top: 100%;
  left: 50%;
  margin-left: -50%;
}


.tooltip-left {
  top: -5px;
  right: 105%;
}
