.window-maximized {
  top: 0 !important;
  left: 0 !important;
  transform: unset !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.window-minimized {
  top: 0 !important;
  width: 150px !important;
  height: 0 !important;
  opacity: 0 !important;
  left: 0 !important;
  transform: unset !important;
}

.window-closing {
  opacity: 0 !important;
  transition: opacity 200ms ease-in-out;
}
