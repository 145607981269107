.danger {
  background-color: #ff6b6b;
}

.warning {
  background-color: #feca57;
}

.success {
  background-color: #10ac84;
}

.large-button {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 100px !important;
}

.app-layout-wrapper {
  display: flex;
  flex-direction: column;

  textarea, input {
    resize: none;
    border: solid 1px #cccccc;
    border-radius: 8px;
  }

  .item-full-width {
    width: 100%;
  }

  .item-full-height {
    height: 100%;
  }

  .bg-info {
    background-color: #f5f6fa;
  }

  .bg-danger {
    background-color: #ff6b6b;
  }

  .bg-warning {
    background-color: #feca57;
  }

  .bg-success {
    background-color: #10ac84;
  }

}

